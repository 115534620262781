import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import loadable from '@loadable/component'
import {
  TextField,
  useGooglePlaceAutocomplete,
  Image,
  Geocode,
  Coordinates,
  OnMapChange,
  CountryCode,
  useMap,
  checkDistricts,
  DistrictProps,
  spinner,
} from '@ecommerce/shared'
import { DistrictOption } from './DistrictSelector'

const loadableOptions = {
  ssr: false,
}
const DistrictSelector = loadable(() => import('./DistrictSelector'), loadableOptions)
const InvalidAddressAlert = loadable(() => import('../InvalidAddressAlert'), loadableOptions)
const InfoMessage = loadable(() => import('@ecommerce/shared'), {
  resolveComponent: (components) => components.InfoMessage,
  ...loadableOptions,
})

declare const window: Window

type Prediction = google.maps.places.AutocompletePrediction
type Props = {
  onSelect: (val: string, geocode?: Geocode) => void
  onInputChange: (val: string, hasClickedMap?: boolean) => void
  onMapClick: (val: string, geocode: Coordinates) => void
  city: string
  country: CountryCode
  errorMessage?: string
  status?: 'success' | 'error'
  defaultValue?: string
  defaultGeocode?: {
    latitude?: number
    longitude?: number
  }
  shouldClear?: boolean
  districtState: DistrictProps
}

const placeholderUrl =
  'https://images.ctfassets.net/16npdkkoi5mj/1pIE21tdFVCHOPcshohS7s/d8488484fd90be4db9bd52bd8d952c5c/map-placeholder.jpg?h=250'
const mapPin =
  'https://images.ctfassets.net/16npdkkoi5mj/5QlwfaRDx04LOqztiU8Kjz/c603f995534af57fe75f33446af6dc5c/location.svg?h=250'

const Wrapper = styled.div<{ isLoading: boolean }>`
  width: 100%;
  margin-bottom: 20px;
  position: relative;

  .InputWrapper {
    margin-bottom: 20px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    right: 11px;
    top: 38px;
    border-style: solid;
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.primary};
    border-bottom-color: transparent;
    border-left-color: transparent;
    animation: ${spinner} 0.5s infinite linear;
    opacity: ${({ isLoading }) => (isLoading ? '1' : '0')};
  }

  input {
    padding-right: 30px;
  }
`

const ResultsWrapper = styled.div<{ isShown: boolean }>`
  max-height: 245px;
  overflow-y: scroll;
  background: ${({ theme }) => theme.colors.medium};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.medium};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 12px 12px 16px;
  transition: 0.15s;
  position: absolute;
  margin: 0;
  top: 75px;
  opacity: ${({ isShown }) => (isShown ? '1' : '0')};
  z-index: ${({ isShown }) => (isShown ? '1' : '-1')};

  button {
    font-size: 14px;
    padding: 0 8px;
    min-height: 28px;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: none;
    border: none;
    width: 100%;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  .autocomplete-error {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 14px;
  }

  .google-disclaimer {
    position: absolute;
    bottom: 6px;
    right: 20px;
    height: auto;
    width: 110px;
    display: block;
  }
`

const MapWrapper = styled.div`
  margin: 10px 0 20px;

  .map-container {
    width: calc(100% + 40px);
    height: 250px;
    position: relative;
    left: -20px;
    background: url(${placeholderUrl});
    background-position: center;
    background-size: cover;
  }

  .map-instructions,
  .map-error {
    color: ${({ theme }) => theme.colors.black};
    font-size: 15px;
    text-align: center;
    transition: 0.15s;
  }

  &#map-wrapper {
    .map-error {
      margin: 15px auto;
      width: fit-content;

      span {
        color: ${({ theme }) => theme.colors.error};
        font-weight: bold;
        text-align: left;
      }

      svg {
        fill: ${({ theme }) => theme.colors.error};
      }
    }
  }
`

const AddressInput = ({
  onSelect,
  onInputChange,
  onMapClick,
  defaultValue,
  defaultGeocode,
  city,
  shouldClear,
  country,
  status,
  errorMessage,
  districtState,
}: Props) => {
  const resultsContainer = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const mapRef = useRef<HTMLDivElement>(null)

  const [searchCity, setSearchCity] = useState(districtState.state.selectedRegion ?? city)
  const [shouldClearInput, setShouldClearInput] = useState(shouldClear)

  const {
    setQuery,
    setSelected,
    ready,
    suggestions,
    loadingSuggestions,
    geocode,
    loadingGeocode,
  } = useGooglePlaceAutocomplete({
    city: searchCity,
    countryCode: country,
    attrContainer: resultsContainer.current,
  })
  const [districtSettings, setDistrictSettings] = useState<{ hasMultiple: boolean; data: DistrictOption | null }>({
    hasMultiple: false,
    data: null,
  })

  const [showOptions, setShowOptions] = useState(false)
  const [value, setValue] = useState(defaultValue ?? '')
  const [isSelected, setIsSelected] = useState(false)
  const [addressIsValid, setAddressIsValid] = useState<boolean | null>(null)
  const [inputIsChanging, setInputIsChanging] = useState(false)
  const [typingTimeout, setTipingTimeout] = useState(0)

  // THIS NEEDS IMPROVEMENT
  const [hideErrorMessage, setHideErrorMessage] = useState(true)
  const [showError, setShowError] = useState(false)
  const [mapHasBeenClicked, setMapHasBeenClicked] = useState(false)

  // Map settings
  const onMapChange: OnMapChange = ({ val, lat, lng }) => {
    setValue(val)
    setQuery(val, false)

    setMapHasBeenClicked(true)
    setInputIsChanging(true)
    setShouldClearInput(false)

    onMapClick(val, { lat, lng })
  }

  const { renderMap, map, error, ready: mapIsReady } = useMap({
    container: mapRef,
    onChange: onMapChange,
    city: searchCity,
    country,
    marker: mapPin,
    setDefault: !defaultGeocode,
  })

  useEffect(() => {
    // Check for multiple regions
    const regions = checkDistricts(city)

    if (regions) {
      setDistrictSettings({
        hasMultiple: true,
        data: regions,
      })
    }

    if (defaultValue) {
      setValue(defaultValue)
      setQuery(defaultValue)
    }

    const hideOptionsOnClick = (e: Event) => {
      const target = e.target as HTMLElement

      if (!target.classList.contains('address_option')) {
        setShowOptions(false)
      }
    }

    window.addEventListener('click', hideOptionsOnClick)

    return () => window.removeEventListener('click', hideOptionsOnClick)
  }, [])

  useEffect(() => {
    if (inputRef.current?.defaultValue !== defaultValue && !mapHasBeenClicked) setShowOptions(suggestions.length !== 0)
  }, [suggestions])

  useEffect(() => {
    if (!loadingGeocode && isSelected) {
      return onSelect(value, geocode)
    }
  }, [isSelected, loadingGeocode])

  useEffect(() => {
    const coordinates = geocode !== undefined ? geocode : defaultGeocode
    if (coordinates?.latitude && coordinates.longitude && mapIsReady) {
      renderMap({ lat: coordinates.latitude, lng: coordinates.longitude, markerIcon: mapPin })
    }
  }, [geocode, defaultGeocode, mapIsReady])

  // Validate if address has a number
  const validate = (val: string) => {
    const isValid = /\d/.test(val)

    setAddressIsValid(isValid)

    return isValid
  }

  const clearInput = () => {
    // Update state
    setAddressIsValid(null)
    setValue('')

    // Focus input
    return inputRef.current?.focus()
  }

  useEffect(() => {
    if (shouldClearInput) {
      clearInput()
    }
  }, [shouldClearInput])

  useEffect(() => {
    // Typing timeout
    setInputIsChanging(true)
    clearTimeout(typingTimeout)

    setTipingTimeout(
      setTimeout(() => {
        setInputIsChanging(false)
      }, 1000),
    )
  }, [value])

  const handleChange = (val: string, updateSelected = false, prediction?: Prediction): void => {
    setQuery(val, !updateSelected)
    setValue(val)
    setIsSelected(false)
    onInputChange(value)
    setMapHasBeenClicked(false)

    // Run if an option is selected
    if (updateSelected && validate(val)) {
      setSelected(prediction)
      setIsSelected(true)
      setShowOptions(false)
    }
  }

  // THIS NEEDS IMPROVEMENT
  useEffect(() => {
    setTimeout(() => setHideErrorMessage(false), 3000)
  }, [])

  useEffect(() => {
    const shouldShowError =
      suggestions.length === 0 && !loadingSuggestions && value !== '' && !inputIsChanging && !mapHasBeenClicked
    setShowError(shouldShowError)
  }, [suggestions, loadingSuggestions, inputIsChanging, value])

  // Update selected region
  useEffect(() => {
    const defaultRegion = districtState.state.selectedRegion
    const newRegions = checkDistricts(city)

    setSearchCity(defaultRegion ?? city)

    setDistrictSettings({
      hasMultiple: newRegions !== undefined,
      data: newRegions ?? null,
    })
  }, [city, districtState])

  const onRegionChange = (selected: string | number) => {
    setSearchCity(`${selected}`)
    clearInput()
    onInputChange('')

    return districtState.dispatcher(() => ({
      checkRegion: districtState.state.checkRegion,
      hasError: false,
      selectedRegion: `${selected}`,
    }))
  }

  return (
    <>
      {districtSettings.hasMultiple && districtSettings.data ? (
        <DistrictSelector
          hasError={districtState.state.hasError}
          onSelect={onRegionChange}
          current={districtState.state.selectedRegion}
          option={districtSettings.data}
        />
      ) : null}
      <Wrapper isLoading={loadingSuggestions}>
        {addressIsValid === false ? <InvalidAddressAlert onCloseAlert={clearInput} /> : null}
        <TextField
          data-testid="shipping-address"
          className="InputWrapper"
          ref={inputRef}
          autoComplete="off"
          disabled={!ready}
          onChange={({ target }) => handleChange(target.value)}
          value={value}
          type="text"
          name="shipping_address"
          label="Dirección completa (Calle + Nº)"
          placeholder={`Avenida del Valle 123, ${city}`}
          status={status}
          errorMessage={errorMessage}
        />
        <div ref={resultsContainer} />
        <MapWrapper id="map-wrapper">
          {!hideErrorMessage && (
            <InfoMessage
              isHidden={!showError}
              className="map-error"
              message="No hemos encontrado tu dirección, haz click en el mapa para selecionar tu ubicación"
            />
          )}
          <InfoMessage
            isHidden={!error.exists}
            className="map-error"
            message="Ha habido un error, por favor intenta de nuevo"
          />
          <p style={{ opacity: map ? '1' : '0' }} className="map-instructions">
            Haz click en el mapa para ajustar la dirección
          </p>
          <div className="map-container" ref={mapRef} />
        </MapWrapper>
        <div>
          <ResultsWrapper isShown={showOptions}>
            {suggestions.map((prediction) => (
              <button
                className="address_option"
                type="button"
                key={prediction.place_id}
                onClick={() => handleChange(prediction.description, true, prediction)}
              >
                {prediction.description}
              </button>
            ))}
            <Image
              preload={false}
              alt="powered-by-google"
              src="https://images.ctfassets.net/16npdkkoi5mj/3MhwfZQrA9vCdM25aOVTei/bb865ffdf05b31526b9c8e159ea8e9e7/Powered_by_Google.png?h=250"
              className="google-disclaimer"
            />
          </ResultsWrapper>
        </div>
      </Wrapper>
    </>
  )
}

export default AddressInput
