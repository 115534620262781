import React, { useState, useEffect, useRef } from 'react'
import loadable from '@loadable/component'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  TextField,
  TextArea,
  Button,
  Checkbox,
  useLocation,
  Select,
  InfoMessage,
  updateAddress,
  checkAddress,
  createUserAddress,
  FormattedAddress,
  Geocode,
  Coordinates,
  useAuth,
  useActivityDialog,
  useResolution,
  updateFavoriteAddress,
  getAuth,
  useDistricts,
} from '@ecommerce/shared'
import Layout from '../../Layout'
import { Icon } from '../../Icon/Icon'
import AddressInput from '../../Checkout/components/AddressInput'
import secrets from '../../../config/secrets'
import { CurrentCity } from '../../../types/PgPages'
import { updateFavorites } from '../../Checkout/utils'
import { schema, clearState } from './utils'
import { getNavBar } from '../CustomNavBar'
import { Wrapper, BackButton, PageInner, Form } from './Layout'

const OutOfRangeModal = loadable(() => import('../../Checkout/components/OutOfRangeModal'))

const { COUNTRY } = secrets

export type Props = {
  pageContext: CurrentCity
  location: {
    state: {
      action: 'edit-address' | 'new-address'
      isDefault: boolean
      edittingAddress: FormattedAddress
      useCurrentCity: boolean
      addresses: FormattedAddress[]
    }
  }
}

const AddressForm = (props: Props) => {
  const { isDesktop } = useResolution()
  const {
    pageContext: { currentCity },
    location: { state },
  } = props
  const { isDefault, edittingAddress, useCurrentCity, addresses } = state ?? {}
  const {
    state: { ownerId, firstName, lastName },
  } = useAuth()
  const {
    state: { byHashCities },
    textByCountry,
    isBolivia,
  } = useLocation()
  const isBO = isBolivia()

  const cities = Object.values(byHashCities).map(({ name, internalName }) => ({ label: name, value: internalName }))

  const [selectedCity, setSelectedCity] = useState({
    name: currentCity.name,
    internalName: currentCity.internalName,
    id: currentCity.id,
    map: currentCity.googleEmbedId,
  })
  const [geocode, setGeocode] = useState<Geocode | null>(null)
  const [clearAddress, setClearAddress] = useState(false)
  const [addressHasChanged, setAddressHasChanged] = useState(false)

  const addressRef = useRef<string>(edittingAddress?.shipping_address)

  // Modal settings
  const { setTitle, setBody, onOpenModal, onCloseModal, ref } = useActivityDialog()

  // Regions settings
  const { districtState, setDistrictState } = useDistricts({
    city: selectedCity.name,
    edittingAddress,
    context: 'my-addresses',
  })

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const { register, setValue, errors, watch, handleSubmit, clearErrors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'all',
  })
  const fields = watch()

  // Clear address input value if is out of range
  const clearAddresssInput = () => {
    // Clear values
    setValue('shipping_address', '')
    setClearAddress(true)
    setGeocode(null)

    onCloseModal()
  }

  // Previous page url
  const previousPath = `/${currentCity.slug}/mis-direcciones`

  useEffect(() => {
    if (!state || !getAuth()) {
      navigate(previousPath)
    }

    window.addEventListener('beforeunload', clearState)

    register({ name: 'city' })
    register({ name: 'shipping_address' })

    if (edittingAddress) {
      setValue('shipping_address', edittingAddress.shipping_address)
      setValue('city', edittingAddress.cityName)

      // Update geocode
      const latitude = edittingAddress?.geocode?.latitude
      const longitude = edittingAddress?.geocode?.longitude
      if (latitude && longitude) {
        setGeocode({ latitude, longitude, country: COUNTRY })
      }

      setDistrictState((prev) => ({ ...prev, selectedRegion: edittingAddress.region }))

      // Set city
      const selected_city = Object.values(byHashCities).filter(({ id }) => id === edittingAddress.cityId)[0]
      setSelectedCity({
        name: selected_city.name,
        internalName: selected_city.internalName,
        id: selected_city.id,
        map: selected_city.googleEmbedId,
      })
    } else if (useCurrentCity) {
      setValue('city', selectedCity.name)
    }

    // Set modal content
    setTitle('Sin cobertura')
    setBody(<OutOfRangeModal mapID={selectedCity.map} city={selectedCity.name} onClose={clearAddresssInput} />)

    // Cleanup
    return () => clearState()
  }, [])

  const handleCitySelect = (selected: string | number) => {
    if (selected !== selectedCity.internalName) {
      setClearAddress(true)
      setValue('shipping_address', undefined)
    }
    const newCity = Object.values(byHashCities).filter((city) => selected === city.internalName)[0]

    setSelectedCity({
      name: newCity.name,
      internalName: newCity.internalName,
      id: newCity.id,
      map: newCity.googleEmbedId,
    })

    setValue('city', selected, { shouldValidate: true })
    setBody(<OutOfRangeModal mapID={newCity.googleEmbedId} city={newCity.name} onClose={clearAddresssInput} />)
  }

  const handleAddressSelect = (value: string, gcode?: Geocode) => {
    setValue('shipping_address', value, { shouldValidate: true })
    addressRef.current = value

    if (gcode) setGeocode(gcode)
    setAddressHasChanged(false)
    setClearAddress(false)
  }

  const handleAddressChange = (value: string) => {
    const hasChanged = value !== addressRef.current
    if (hasChanged) {
      setValue('shipping_address', '')
      setClearAddress(false)
    }

    setAddressHasChanged(hasChanged)
  }

  const handleMapClick = (value: string, gcode: Coordinates) => {
    setValue('shipping_address', value)
    clearErrors('shipping_address')
    setGeocode({ latitude: gcode.lat, longitude: gcode.lng, country: COUNTRY })
    setAddressHasChanged(false)
  }

  const onFormSubmit = async () => {
    if (!isLoading) {
      try {
        // Check region
        if (districtState.checkRegion && !districtState.selectedRegion) {
          return setDistrictState((prev) => ({ ...prev, hasError: true }))
        }

        // Show loader
        setLoading(true)

        // Hide errors
        setError(false)
        setClearAddress(false)

        let addressID = ''

        // Check address
        let isInRange = false
        const cityId = selectedCity.id

        if (geocode) {
          isInRange = await checkAddress({
            lat: geocode.latitude,
            long: geocode.longitude,
            id: cityId,
            country: COUNTRY,
          })
        }

        if (!isInRange) {
          onOpenModal()
        } else {
          if (edittingAddress) {
            await updateAddress({
              ownerId,
              id: edittingAddress?.id,
              attributes: {
                city: selectedCity.internalName,
                first_name: firstName,
                last_name: lastName,
                full_name: `${firstName} ${lastName}`,
                country_code: COUNTRY,
                state_code: selectedCity.id.toString(),
                line_1: fields.shipping_address ?? edittingAddress?.shipping_address,
                line_2: fields.shipping_number ?? edittingAddress?.shipping_number,
                phone: fields.shipping_phone ?? edittingAddress?.shipping_phone,
                notes: fields.shipping_instructions ?? edittingAddress?.shipping_instructions,
                lat: geocode?.latitude ?? edittingAddress.geocode.latitude,
                lng: geocode?.longitude ?? edittingAddress.geocode.longitude,
                zip_code: districtState.selectedRegion ?? edittingAddress.region,
                metadata: {
                  favorite: edittingAddress?.isFavorite ?? false,
                  alias: fields.shipping_name ?? edittingAddress?.shipping_name,
                  cityId: selectedCity.id,
                  cityName: selectedCity.name,
                  region: districtState.selectedRegion ?? edittingAddress.region,
                },
              },
            })
          } else {
            const newAddress = await createUserAddress({
              ownerId,
              id: 'ID',
              attributes: {
                city: selectedCity.internalName,
                first_name: firstName,
                last_name: lastName,
                full_name: `${firstName} ${lastName}`,
                country_code: COUNTRY,
                state_code: selectedCity.id.toString(),
                line_1: fields.shipping_address,
                line_2: fields.shipping_number,
                phone: fields.shipping_phone,
                notes: fields.shipping_instructions,
                lat: geocode?.latitude,
                lng: geocode?.longitude,
                zip_code: districtState.selectedRegion ?? selectedCity.name,
                metadata: {
                  favorite: false,
                  alias: fields.shipping_name,
                  cityId: selectedCity.id,
                  cityName: selectedCity.name,
                  region: districtState.selectedRegion ?? selectedCity.name,
                },
              },
            })

            addressID = newAddress.id
          }

          if (fields.favorite) {
            const id = edittingAddress ? edittingAddress.id : addressID
            const newFavorites = updateFavorites(addresses, true, id)

            updateFavoriteAddress(newFavorites)
          }

          setTimeout(() => navigate(previousPath), 800)
        }
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
  }

  const canSave =
    fields.city !== '' &&
    fields.shipping_address !== '' &&
    fields.shipping_phone !== '' &&
    fields.shipping_name !== '' &&
    geocode !== null &&
    !addressHasChanged &&
    !errors.shipping_instructions
  const selectPlaceholder = useCurrentCity ? currentCity.name : edittingAddress?.cityName

  const NavbarWithProp = () => getNavBar(previousPath)

  return (
    <Layout
      title={`${state?.action === 'edit-address' ? 'Editar' : 'Añadir'} Direccion`}
      currentCity={currentCity}
      navbar={isDesktop ? undefined : NavbarWithProp}
    >
      <Wrapper ref={ref}>
        <PageInner>
          <BackButton onClick={() => navigate(previousPath)}>
            <Icon iconId="arrow_left" />
            Volver
          </BackButton>
          <h1>Datos de nueva dirección</h1>
          <Form onSubmit={handleSubmit(onFormSubmit)}>
            <Select
              name="city"
              className="CitySelect"
              label={useCurrentCity ? 'Seleccionar Ciudad' : ''}
              placeholder={selectPlaceholder ?? 'Seleccionar Ciudad'}
              options={cities}
              onSelect={handleCitySelect}
              status={!errors?.city ? undefined : 'error'}
              errorMessage={errors?.city?.message}
              changeText="Cambiar"
            />
            <AddressInput
              country={COUNTRY}
              city={selectedCity.name}
              onInputChange={handleAddressChange}
              onSelect={handleAddressSelect}
              status={!errors?.shipping_address ? undefined : 'error'}
              errorMessage={errors?.shipping_address?.message}
              defaultValue={edittingAddress?.shipping_address}
              defaultGeocode={edittingAddress?.geocode}
              districtState={{ state: districtState, dispatcher: setDistrictState }}
              onMapClick={handleMapClick}
              shouldClear={clearAddress}
            />
            <TextField
              maxLength={isBO ? 50 : undefined}
              className="address-form-input house-number"
              ref={register}
              type="text"
              name="shipping_number"
              defaultValue={edittingAddress?.shipping_number}
              label="Depto. / Casa Condominio (Opcional)"
              placeholder="Depto. 27"
            />
            <TextField
              maxLength={isBO ? 50 : undefined}
              className="address-form-input"
              type="text"
              ref={register}
              name="shipping_name"
              defaultValue={edittingAddress?.shipping_name}
              label="Nombre etiqueta"
              placeholder="Ej: Casa"
              status={!errors?.shipping_name ? undefined : 'error'}
              errorMessage={errors?.shipping_name?.message}
            />
            <TextField
              maxLength={isBO ? 20 : undefined}
              className="address-form-input"
              type="tel"
              ref={register}
              name="shipping_phone"
              defaultValue={edittingAddress?.shipping_phone}
              label="Teléfono de contacto"
              placeholder={textByCountry('+56923456789', '23456789')}
              status={!errors?.shipping_phone ? undefined : 'error'}
              errorMessage={errors?.shipping_phone?.message}
            />
            <TextArea
              label="Agrega instrucciones especiales para tu entrega: (Opcional)"
              className="address-notes"
              name="shipping_instructions"
              defaultValue={edittingAddress?.shipping_instructions}
              ref={register}
              placeholder="(Ej: Dejar en conserjería, tocar timbre para abrir portón, etc.)"
              maxLength={200}
            />
            {/*
             DESHABILITADO TEMPORALMENTE
            <div className="favorite-input">
              <Checkbox defaultChecked={edittingAddress?.isFavorite} ref={register} name="favorite" />
              <span>Usar esta dirección como favorita</span>
            </div> */}
            <Button isLoading={isLoading} isDisabled={!canSave} type="submit">
              Guardar
            </Button>
            <InfoMessage
              className="error-message"
              isHidden={!error}
              message="Error en la conexión, Intenta de nuevo por favor."
            />
          </Form>
        </PageInner>
      </Wrapper>
    </Layout>
  )
}

export default AddressForm
