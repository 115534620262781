import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { Image, Anchor, NavBar } from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'

const CenterWrapper = styled.div`
  .img-logo {
    height: 50px;
    object-fit: contain;
  }
`
const center = (onLogoClick?: () => void) => (
  <CenterWrapper>
    <Anchor onClick={() => (onLogoClick ? onLogoClick() : null)}>
      <Image
        src="https://images.ctfassets.net/16npdkkoi5mj/4EAocVvRjUs3wHYN7PiSDB/f89865bb82569f8a649d1c07fbfbfbd5/CCO_-_BLANCO_-_SVG.svg"
        alt="logo"
        className="img-logo"
      />
    </Anchor>
  </CenterWrapper>
)

const CustomNavbar = ({ navigatePath = '../', onLogoClick }: { navigatePath: string; onLogoClick?: () => void }) => {
  const left = (
    <Anchor onClick={() => navigate(navigatePath)}>
      <Icon iconId="arrow_left" color="white" size="24" />
    </Anchor>
  )

  return <NavBar left={left} center={center(onLogoClick)} />
}

export const getNavBar = (slug: string, onLogoClick?: () => void) => (
  <CustomNavbar navigatePath={slug} onLogoClick={onLogoClick} />
)

export default CustomNavbar
